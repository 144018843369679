import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Callback from 'components/auth/callback.jsx';
import Logout from 'components/auth/logout.jsx';
import LogoutCallback from 'components/auth/logoutCallback.jsx';
import PrivateRoute from 'routes/privateRoute.jsx';
import SilentRenew from 'components/auth/silentRenew.jsx';
import CompleteProfile from 'views/CompleteProfile.jsx';
import { UserRoles } from 'constants/enums.js';
import Recover from 'views/Recover.jsx';
import ResetPassword from 'views/ResetPassword.jsx';
import VerifyChangedEmail from 'views/VerifyChangedEmail.jsx';
import PrivacyPolicy from 'views/PrivacyPolicy.jsx';
import TermsOfUse from 'views/TermsOfUse.jsx';
import Help from 'views/Help.jsx';
import ContinueAs from 'containers/ContinueAs.jsx';
import HelloLandingPage from 'views/HelloLandingPage.jsx';
import TechSpecs from 'views/TechSpecs.jsx';
import Home from 'views/Partials/Home.jsx';
import QrCode from 'views/QrCode.jsx';
import AlertCenterMayo from 'views/AlertCenterMayo.jsx';

const DefaultRoutes = (
	<Switch>
		<Route exact={true} path='/signin-oidc' component={Callback} />
		<Route exact={true} path='/logout' component={Logout} />
		<Route exact={true} path='/logout/callback' component={LogoutCallback} />
		<Route exact={true} path='/silent-renew' component={SilentRenew} />
		<Route exact={true} path='/recover' component={Recover} />
		<Route exact={true} path='/validate/recoverPassword' component={ResetPassword} />
		<Route exact={true} path='/validate/changeEmail' component={VerifyChangedEmail} />
		<Route path='/complete-profile' component={CompleteProfile} />
		<Route path='/privacy-policy' component={PrivacyPolicy} />
		<Route path='/terms-of-use' component={TermsOfUse} />
		<Route path='/help' component={Help} />
		<Route path='/qrcode' component={QrCode} />
		<Route exact={true} path='/' component={Home} />
		<Route exact={true} path='/hello/specs' component={TechSpecs} />
		<Route exact={true} path='/hello' component={HelloLandingPage} />
		<PrivateRoute exact path='/continue-as' component={ContinueAs} />
		<PrivateRoute roles={[UserRoles.NURSE, UserRoles.VIRTUAL_SITTER]} path='/care-notifications' component={AlertCenterMayo} />
		<PrivateRoute path='*' component={Home} />
	</Switch>
);

export default DefaultRoutes;
