import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import { getAiSettingsConfigurations, getConfigurationValue, updateConfigsList } from 'infrastructure/helpers/commonHelpers.js';
import OutOfBed from 'icons/Monitoring/OutOfBed.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { CallTypes, DeviceStatusMessages, ParticipantRemoveReason, ParticipantState, PatientAiSetting } from 'constants/enums.js';
import Rails from 'icons/Monitoring/Rails.jsx';
import FallDetection from 'icons/Monitoring/FallDetection.jsx';
import PatientMobility from 'icons/Monitoring/PatientMobility.jsx';
import PressureInjury from 'icons/Monitoring/PressureInjury.jsx';
import PatientPrivacy from 'icons/Monitoring/PatientPrivacy.jsx';
import HandWashing from 'icons/Monitoring/HandWashing.jsx';
import HeartRate from 'icons/Monitoring/HeartRate.jsx';
import IvBag from 'icons/Monitoring/IvBag.js';
import Eye from 'icons/Monitoring/Eye.jsx';
import {
	AiSetting,
	CallWorkflowType,
	SettingsCategory,
	UserSettingTypes,
	configurableAISettings,
	configurableMonitoringMenu,
	MonitoringSettings,
} from 'constants/configurationEnums.js';
import { getMonitoringSettingOptions, getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import classNames from 'classnames';
import { getPrecautions } from 'api/monitoring.js';
import { getDeviceOwnerPatient } from 'api/patients.js';
import Alert from 'components/Alert.jsx';
import _ from 'lodash';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import Grid from 'components/Grid.jsx';
import Tooltip from 'calls/components/Tooltip.jsx';
import ToastMessage from 'components/ToastMessage.jsx';
import Button from 'components/Button.jsx';
import { getUserRole } from 'infrastructure/auth.js';

const initialPrecautions = [
	{
		id: '22631001',
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#F3C752',
	},
	{
		id: '77272004',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#E270DA',
	},
	{
		id: '441862004',
		abbreviation: 'ISO',
		name: 'Isolation',
		textColor: '#FFFFFF',
		boxColor: '#D64F2D',
	},
	{
		id: '413322009',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#282D30',
		boxColor: '#88D9FB',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
	{
		id: '49436004',
		abbreviation: 'HP',
		name: 'Hospice',
		textColor: '#FFFFFF',
		boxColor: '#000000',
	},
	{
		id: '71388002',
		abbreviation: 'D',
		name: 'Detox',
		textColor: '#282D30',
		boxColor: '#F2A356',
	},
	{
		id: '233604007',
		abbreviation: 'PO2',
		name: 'Pulling O2',
		textColor: '#282D30',
		boxColor: '#B6D7E4',
	},
	{
		id: '35489007',
		abbreviation: 'IC',
		name: 'Impulsive/Confused',
		textColor: '#282D30',
		boxColor: '#D3D3D3',
	},
	{
		id: '43998006',
		abbreviation: 'NR',
		name: 'Non-redirectable',
		textColor: '#FFFFFF',
		boxColor: '#4BA5F8',
	},
];

const AlertFeed = ({
	className,
	feed,
	onCloseClick,
	onManageAlertClick,
	intl,
	onProviderIntervening,
	isFromMonitoring = false,
	onToggleAlert,
	toggleToMonitoring,
	onDragStart,
	onDragOver,
	onDragEnd,
	reAddFeed,
	closeDeviceLockedModal,
	callManager,
	privacyModeError = false,
	closePrivacyModeError,
	isDefaultOwner = false,
	setDiscontinueMonitoringReasons,
}) => {
	const [precautions, setPrecautions] = useState([]);
	const user = useSelector(state => state.user);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);

	const [error, setError] = useState('');
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [nameOfNurseAlsoMonitoring, setNameOfNurseAlsoMonitoring] = useState('');
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));
	const socket = useContext(SocketContext);
	const dispatch = useDispatch();

	const prevDeviceStateRef = useRef(null);
	const maxSimultaneousMonitoringFeedsReachedRef = useRef(false);

	useEffect(() => {
		const callStateChanged = data => {
			if (data.deviceId === feed.deviceId) {
				const activeDeviceMonitoringFeeds = data.activeConferences?.filter(
					feed => feed.callType === CallTypes.MONITORING
				)?.length;
				const prevIsDeviceOnACall = prevDeviceStateRef.current?.activeConferences?.some(
					feed =>
						feed.callType === CallTypes.SECURITY_CAM || feed.callType === CallTypes.VIDEO || feed.callType === CallTypes.AUDIO
				);
				const isDeviceOnACall = data.activeConferences.some(
					feed =>
						feed.callType === CallTypes.SECURITY_CAM || feed.callType === CallTypes.VIDEO || feed.callType === CallTypes.AUDIO
				);

				if (feed.showDeviceControlsLockedModal && !isDeviceOnACall) {
					closeDeviceLockedModal();
				}
				const MaxSimultaneousMonitoringFeeds = 2;
				if (
					prevIsDeviceOnACall &&
					!isDeviceOnACall &&
					activeDeviceMonitoringFeeds < MaxSimultaneousMonitoringFeeds &&
					(feed.reason === ParticipantRemoveReason.DISCONNECTED_BY_CALL || feed.status === ParticipantState.BUSY.type)
				) {
					reAddFeed(feed.deviceId);
				}
				prevDeviceStateRef.current = data;
				const monitoringConnections = data.activeConferences?.filter(feed => feed.callType === CallTypes.MONITORING);

				maxSimultaneousMonitoringFeedsReachedRef.current = activeDeviceMonitoringFeeds >= MaxSimultaneousMonitoringFeeds;
				setNameOfNurseAlsoMonitoring(monitoringConnections.length > 0 ? monitoringConnections?.at(0)?.name : '');
			}
		};

		callManager?.on('call-state-changed', callStateChanged);

		return () => {
			callManager?.off('call-state-changed', callStateChanged);
		};
	}, [callManager, feed]);

	useEffect(() => {
		const fetchDiscontinueMonitoringReasons = async () => {
			const response = await getMonitoringSettingOptions(feed?.healthSystemId, MonitoringSettings.DiscontinueMonitoring);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setDiscontinueMonitoringReasons(response);
		};
		if (isFromMonitoring) {
			fetchDiscontinueMonitoringReasons();
		}
	}, [feed?.healthSystemId]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const [myRoomSettings, adminAiSettings, aiRoomSettings, adminRoomSettings] = await Promise.all([
				getUserPreferences(UserSettingTypes.Monitoring, feed.roomId),
				getRoomSettings(feed.roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, feed.roomId),
				getRoomSettings(feed.roomId, SettingsCategory.MONITORING),
			]);
			const responseError = myRoomSettings.error || adminAiSettings.error || aiRoomSettings.error || adminRoomSettings.error;
			if (responseError) {
				setError(responseError.message);
				return;
			}
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings);
			const adminResponse = updateConfigsList(adminRoomSettings, myRoomSettings);

			if (aiResponse.error) {
				setError(aiResponse.error);
			} else {
				setAdminAiConfigurations(aiResponse.configs);
			}
			setAdminConfigurations(adminResponse.configs);
		};
		fetchRoomSettings();
	}, [feed.roomId]);

	useEffect(() => {
		const fetchPrecautions = async ownerId => {
			const response = await getPrecautions(ownerId);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const newPrecautions = _.cloneDeep(initialPrecautions).map(x => {
				const precaution = response.conditions.find(p => p.code === x.id);
				if (precaution) {
					x.active = true;
				}

				return x;
			});
			setPrecautions(newPrecautions);
		};

		const getOwner = async () => {
			const deviceOwnerResponse = await getDeviceOwnerPatient(feed.deviceId);
			if (deviceOwnerResponse.error) {
				setError(intl.formatMessage({ id: 'fetchingPrecautionsFailed' }));
				return;
			}

			if (!isDefaultOwner) {
				fetchPrecautions(deviceOwnerResponse?.healthcareUserId);
			}
		};

		getOwner();
	}, [feed.deviceId, feed.roomName, intl, isDefaultOwner]);

	useEffect(() => {
		if (feed.intervention?.value) {
			setTimeout(() => {
				onProviderIntervening(feed.deviceId);
			}, 20000);
		}
	}, [feed.intervention, feed.deviceId, onProviderIntervening]);

	useEffect(() => {
		const handlePrecautionsUpdate = data => {
			if (!data.deviceId || !data.conditions || data.deviceId !== feed.deviceId) {
				return;
			}
			const newPrecautions = _.cloneDeep(initialPrecautions).map(precaution => {
				const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
				if (foundPrecaution) {
					precaution.active = true;
				}
				return precaution;
			});
			setPrecautions(newPrecautions);
		};
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, handlePrecautionsUpdate);
		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, handlePrecautionsUpdate);
		};
	}, [socket, feed.deviceId, intl]);

	const getValue = settingTypeId => {
		const found = aiSettingList
			.find(item => item.deviceId === feed.deviceId)
			?.settings.find(item => item.settingTypeId === settingTypeId);
		if (!found) {
			return false;
		}
		return found.isEnabled;
	};

	const aiTypesToShow = [
		{
			aiTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
			label: intl.formatMessage({ id: 'getOutOfBed' }),
			img: <OutOfBed color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.GET_OUT_OF_BED]) &&
				getValue(PatientAiSetting.PATIENT_GETTING_OUT_OF_BED),
		},
		{
			aiTypeId: PatientAiSetting.RAILS,
			label: intl.formatMessage({ id: 'rails' }),
			img: <Rails color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.RAILS]) && getValue(PatientAiSetting.RAILS),
		},
		{
			aiTypeId: PatientAiSetting.FALL_DETECTED,
			label: intl.formatMessage({ id: 'fallDetection' }),
			img: <FallDetection color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.FALL_DETECTION]) &&
				getValue(PatientAiSetting.FALL_DETECTED),
		},
		{
			aiTypeId: PatientAiSetting.PATIENT_MOBILITY,
			label: intl.formatMessage({ id: 'patientMobility' }),
			img: <PatientMobility color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.PATIENT_MOBILITY]) &&
				getValue(PatientAiSetting.PATIENT_MOBILITY),
		},
		{
			aiTypeId: PatientAiSetting.PRESSURE_INJURY,
			label: intl.formatMessage({ id: 'pressureInjury' }),
			img: <PressureInjury color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.PRESSURE_INJURY]) &&
				getValue(PatientAiSetting.PRESSURE_INJURY),
		},
		{
			aiTypeId: PatientAiSetting.SKELETON_PRIVATE_MODE,
			label: intl.formatMessage({ id: 'privacyMode' }),
			img: <PatientPrivacy color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.AI_PRIVACY_MODE]) &&
				getValue(PatientAiSetting.SKELETON_PRIVATE_MODE),
		},
		{
			aiTypeId: PatientAiSetting.HAND_WASHING,
			label: intl.formatMessage({ id: 'handWashing' }),
			img: <HandWashing color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.HAND_WASHING]) && getValue(PatientAiSetting.HAND_WASHING),
		},
		{
			aiTypeId: PatientAiSetting.VITAL_SIGNS_AI,
			label: intl.formatMessage({ id: 'ewsAiVitalSigns' }),
			img: <HeartRate color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.EWS_AI_VITAL_SIGNS]) &&
				getValue(PatientAiSetting.VITAL_SIGNS_AI),
		},

		{
			aiTypeId: PatientAiSetting.IV_BAG,
			label: intl.formatMessage({ id: 'ivBagFluidLevel' }),
			img: <IvBag color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show:
				getConfigurationValue(adminAiSettingsConfigurations[AiSetting.IV_BAG_FLUID_LEVEL]) && getValue(PatientAiSetting.IV_BAG),
		},
	];

	useEffect(() => {
		const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));
		const onPatientAiSettingsUpdated = ({ updatedByUserId, patientAiSettings, deviceId, workflowType }) => {
			if (user.userId === updatedByUserId || feed.deviceId !== deviceId || workflowType === CallWorkflowType.ROUNDING) {
				return;
			}
			setAiSettingsAction({ deviceId, settings: patientAiSettings });
		};
		socket.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		return () => {
			socket.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		};
	}, [feed.deviceId, dispatch, socket, user.userId]);

	const getDeviceStatus = () => {
		const getRetryButton = () => {
			return (
				<>
					<br />
					<Button onClick={() => reAddFeed(feed.deviceId)} className='re-add-feed' text={translate('retry')} />
				</>
			);
		};

		const getNursesInMonitoringNames = () => {
			let names = '';

			if (feed.nursesInConference?.length > 1) {
				names = feed.nursesInConference.map(obj => obj.name).join(` ${intl.formatMessage({ id: 'and' })} `);
			}

			return names;
		};

		if (feed.status === ParticipantState.CONNECTED.type && feed.isCameraPrivacyOn) {
			return DeviceStatusMessages.privacyModeOn;
		}
		switch (feed.status) {
			case ParticipantState.BUSY.type:
				if (maxSimultaneousMonitoringFeedsReachedRef.current) {
					return translate('maximumNumberOfMonitoringSessions', { value: getNursesInMonitoringNames() });
				}

				if (feed.onPatientBusyNurse?.name) {
					return `${DeviceStatusMessages.deviceOnCallWithNurse} ${feed.onPatientBusyNurse.name}.`;
				}
				return DeviceStatusMessages.deviceOnCall;
			case ParticipantState.OFFLINE.type:
				return DeviceStatusMessages.deviceOffline;
			case ParticipantState.NOT_ANSWERING.type:
				return (
					<>
						{DeviceStatusMessages.notAnswering}
						{getRetryButton()}
					</>
				);
			case ParticipantState.DISCONNECTED.type:
			case ParticipantState.LEFT_CALL.type:
				if (feed.reason === ParticipantRemoveReason.DISCONNECTED_BY_CALL) {
					return <>{DeviceStatusMessages.disconnectedByCall}</>;
				}
				if (feed.reason === ParticipantRemoveReason.FAILED_TO_GET_INFO) {
					return (
						<>
							{DeviceStatusMessages.failedToGetInfo} {getRetryButton()}
						</>
					);
				}
				return (
					<>
						{DeviceStatusMessages.leftCall} {getRetryButton()}
					</>
				);
			case ParticipantState.RECONNECTING.type:
				return DeviceStatusMessages.reconnecting;
			case ParticipantState.REMOVED.type:
				return DeviceStatusMessages.removed;
			case ParticipantState.INVITE_DENIED.type:
				return DeviceStatusMessages.inviteDenied;
			case ParticipantState.FAILED_TO_GET_INFO.type:
				return (
					<>
						{DeviceStatusMessages.failedToGetInfo}
						<br />
						<Button onClick={() => reAddFeed(feed.deviceId)} className='re-add-feed' text={translate('retry')} />
					</>
				);
			default:
				return DeviceStatusMessages.initiatingMonitoring;
		}
	};

	const filteredPrecautions = precautions?.filter(item => item.active);
	const shownAiTypes = aiTypesToShow.filter(item => item.show);
	const isAmbientConnected = feed.status === ParticipantState.CONNECTED.type;

	return (
		<>
			<div
				className={classNames(
					'alert-feed flex column-direction full-width full-height',
					className || '',
					feed.warning?.isAiAlert || feed.isStatAlarmActive ? 'high-risk-alert' : '',
					feed.isStatAlarmActive ? 'high-risk-stat-alarm-alert' : '',
					isFromMonitoring ? 'monitoring-alert-feed' : ''
				)}
				onClick={isFromMonitoring && isAmbientConnected ? onToggleAlert : null}
				draggable={isFromMonitoring}
				onDragStart={isFromMonitoring ? onDragStart : null}
				onDragOver={isFromMonitoring ? onDragOver : null}
				onDragEnd={isFromMonitoring ? onDragEnd : null}
				style={{
					cursor: isFromMonitoring ? 'move' : 'initial',
				}}>
				<div className='alert-feed-header'>
					<span className='alert-feed-room-name'>
						{isFromMonitoring && nameOfNurseAlsoMonitoring && (
							<Tooltip
								text={`${intl.formatMessage({ id: 'beingMonitoredBy' })} ${nameOfNurseAlsoMonitoring}`}
								isPortal={true}
								position='right'>
								<div className='monitoring-nurses-badge'>
									<span className='material-icons'>
										<Eye />
									</span>
								</div>
							</Tooltip>
						)}
						{feed.roomName}
					</span>
					<span className='flex flex-align-center'>
						{isFromMonitoring && (
							<>
								{isAmbientConnected && (
									<>
										<span className='right-s font-10 monitor-patient-title'>Video</span>
										<div className='flex flex-align-center'>
											<label className='switch-label auto-width right-s'>
												<div className='toggle-switch toggle-blue'>
													<input type='' checked={false} onClick={toggleToMonitoring} />
													<span className='toggle-body'>
														<span className='on-text'>{translate('on')}</span>
														<span className='off-text'>{translate('off')}</span>
													</span>
												</div>
											</label>
										</div>
									</>
								)}

								<div
									className='alert-feed-icon-wrapper'
									onClick={event => {
										if (event) {
											event.stopPropagation();
										}
										onCloseClick(getConfigurationValue(adminConfigurations[MonitoringSettings.DiscontinueMonitoring]));
									}}>
									<i className='material-icons-outlined alert-feed-icon'>close</i>
								</div>
							</>
						)}
					</span>
				</div>
				<div className='alert-feed-content-wrapper flex column-direction flex-align-center position-relative overflow-hidden'>
					{(!isFromMonitoring || (isFromMonitoring && isAmbientConnected)) && (
						<>
							{!feed.isDefaultOwner && (
								<>
									{feed.warning?.isAiAlert && !feed.isStatAlarmActive && (
										<>
											<span className='flex flex-justify-center flex-align-center flex-justify-center'>{feed.warning.icon}</span>
											<span>{feed.warning.text}</span>
											<span
												className='manage-alert-button'
												onClick={isFromMonitoring ? toggleToMonitoring : () => onManageAlertClick(feed)}>
												{translate(isFromMonitoring ? 'turnVideoOn' : 'manageAlert')}
											</span>
										</>
									)}
									{feed.isStatAlarmActive && <span>{translate('statAlarmStarted')}</span>}
									{!feed.warning?.isAiAlert && !feed.isStatAlarmActive && (
										<div className='flex full-height position-relative gap-s'>
											{filteredPrecautions.length > 0 && (
												<Grid
													rows='repeat(4, min-content)'
													gridGap='var(--spacing-s)'
													className={classNames(
														'precautions-grid',
														filteredPrecautions.length > 4 && filteredPrecautions.length <= 8 ? 'precautions-8' : '',
														filteredPrecautions.length > 8 ? 'precautions-10' : ''
													)}>
													{filteredPrecautions.map(item => (
														<div
															className='precaution-box'
															style={{
																background: item.boxColor,
															}}>
															<span style={{ color: item.textColor }}>{item.abbreviation}</span>
														</div>
													))}
												</Grid>
											)}
											<div
												className={classNames(
													'flex column-direction full-height full-width text-align-center gap-s',
													filteredPrecautions.length > 0 ? 'has-precautions' : '',
													shownAiTypes.length === 0 ? 'flex-justify-center' : '',
													filteredPrecautions.length === 0 ? 'no-padding-left' : ''
												)}>
												{!getConfigurationValue(adminAiSettingsConfigurations[AiSetting.SILENT_MODE]) &&
													aiTypesToShow.map(
														item =>
															item.show && (
																<div className='alert-feed-active-case' key={item.aiTypeId}>
																	{item.img} <span className='left-s'>{item.label}</span>
																</div>
															)
													)}
												{shownAiTypes.length === 0 && (
													<span className='unoccupied-room'>{translate('ambientMonitoringNotActive')}</span>
												)}
											</div>
										</div>
									)}
								</>
							)}
							{feed.isDefaultOwner && <span className='unoccupied-room'>{translate('unoccupied')}</span>}
							{feed.intervention?.value && (
								<div className='flex flex-justify-center flex-align-center gap-m position-absolute full-width nurse-intervening-container'>
									<ProfilePicture
										fullName={feed.intervention?.providerFullName}
										profilePicture={feed.intervention?.providerProfilePicture}
										className='provider-intervening-img-wrapper'
									/>
									<span className='flex column-direction left-align-items'>
										<span>{feed.intervention?.providerFullName}</span>
										<span>{translate('isIntervening')}</span>
									</span>
								</div>
							)}
						</>
					)}
					{isFromMonitoring && !isAmbientConnected && <span className='unoccupied-room'>{getDeviceStatus()}</span>}
					<ToastMessage
						showToast={privacyModeError}
						onClose={closePrivacyModeError}
						className='feed-toast-message position-absolute'>
						<span>{translate('cannotEnablePrivacyMode')}</span>
					</ToastMessage>
				</div>
			</div>
			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</>
	);
};

export default AlertFeed;
