import React, { useContext, useMemo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import Grid from 'components/Grid.jsx';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import { AlertTypes, AssignedRequestTypes, HealthSystemType, TaskStatus, UserRoles } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getMyPatients } from 'api/patients.js';
import useScreenType from 'hooks/useScreenType.js';
import { actionCreators as patientActionCreators } from 'state/patients/actions.js';
import { getPatientQueueCount } from 'api/users.js';
import { getLevelAssignedRequests, getProviderAssignedRequests } from 'api/doctorRequests.js';
import {
	getConfigurationValue,
	getStorage,
} from 'infrastructure/helpers/commonHelpers.js';
import { TelemedicineModesSettings, GeneralSettings } from 'constants/configurationEnums.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { LOCALES } from 'i18n-translations/locales.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import Loader from 'components/Loader.jsx';
import LeftNavigationMayo from 'components/LeftNavigationMayo.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';

const MainLayout = props => {
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [isNewPatientCheckInModalVisible, setIsNewPatientCheckInModalVisibility] = useState(false);
	const [selectedPage, setSelectedPage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [taskCompleted, setTaskCompleted] = useState(null);
	const dispatch = useDispatch();
	const toggleLeftNavigation = () => dispatch(healthSystemsActionCreators.toggleLeftNavigation());
	const healthSystems = useSelector(state => state.healthSystems);
	const company = useSelector(state => state.company);
	const doctor = useSelector(state => state.myDoctors);
	const user = useSelector(state => state.user);
	const locale = useSelector(state => state.language.locale);
	const configurations = useSelector(state => state.configurations);
	const { current: userRole } = useRef(getUserRole());
	const setUserWaitingRoomCount = count => dispatch(userActionCreators.setUserWaitingRoomCount(count));
	const setUserSessionQueueCount = count => dispatch(userActionCreators.setUserSessionQueueCount(count));
	const socket = useContext(SocketContext);
	const history = useHistory();
	const screenType = useScreenType();
	const selectedPatient = useSelector(state => state.myPatients.selectedPatient);
	const shouldApplyResponsive = screenType.isSmall && (healthSystems.isLeftNavigationExpanded || healthSystems.isRightPanelOpen);
	const isPrimaryCareType = useMemo(() => {
		return (
			healthSystems?.allHealthSystems?.find(item => item.id === user.userSession.healthSystem.id)?.workflowTypeId ===
			HealthSystemType.PRIMARY_CARE
		);
	}, [healthSystems?.allHealthSystems, user.userSession.healthSystem.id]);

	useEffect(() => {
		let newRequestSound = new Audio(`${healthCareCdnUrl}sound/you-have-new-message.mp3`);
		const handleRequestUpdated = async data => {
			const { waitingRoomCount, assignedRequestTypeId } = data;
			if (history.location.pathname !== '/waiting-room' && assignedRequestTypeId === AssignedRequestTypes.WITHOUT_INTAKE_FORM) {
				setUserWaitingRoomCount(user.waitingRoomCount + 1);
			}
			if (history.location.pathname !== '/sessions-queue' && assignedRequestTypeId === AssignedRequestTypes.WITH_INTAKE_FORM) {
				setUserSessionQueueCount(user.waitingRoomCount + 1);
			}
			if (waitingRoomCount > doctor.waitingRoomCount) {
				try {
					newRequestSound.loop = false;
					await newRequestSound.play();
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log('Autoplay failed:', error);
				}
			}
		};

		const getEventBasedOnConfig = () =>
			getConfigurationValue(companyConfigurations[TelemedicineModesSettings.SHARED_QUEUE])
				? SocketEvents.HealthCare.ON_LEVEL_REQUEST_UPDATED
				: SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED;

		if ([UserRoles.DOCTOR, UserRoles.NURSE].includes(userRole)) {
			socket.on(getEventBasedOnConfig(), handleRequestUpdated);
		}
		return () => {
			newRequestSound = null;
			if ([UserRoles.DOCTOR, UserRoles.NURSE].includes(userRole)) {
				socket.off(getEventBasedOnConfig(), handleRequestUpdated);
			}
		};
	}, [userRole, socket, doctor.waitingRoomCount, dispatch, history.location.pathname]);

	useEffect(() => {
		const handleQueueUpdated = data => {
			const { assignedRequestTypeId } = data;
			if (assignedRequestTypeId === AssignedRequestTypes.WITHOUT_INTAKE_FORM) {
				setUserWaitingRoomCount(user.waitingRoomCount + 1);
			}
			if (assignedRequestTypeId === AssignedRequestTypes.WITH_INTAKE_FORM) {
				setUserSessionQueueCount(user.waitingRoomCount + 1);
			}
		};
		socket.on(SocketEvents.HealthCare.PATIENT_QUEUE_UPDATED, handleQueueUpdated);
		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_QUEUE_UPDATED, handleQueueUpdated);
		};
	}, [socket, user.waitingRoomCount]);

	useEffect(() => {
		const handleTaskCompleted = data => {
			if (['/rpm-patient-onboarding', '/check-in-patient', '/dashboard/detailed-report'].includes(history.location.pathname)) {
				return;
			}
			if (data.taskStatusId === TaskStatus.COMPLETED) {
				setTaskCompleted(translate('taskCompleted'));
			}
			if (data.taskStatusId === TaskStatus.FAULTED) {
				setTaskCompleted(translate('somethingWentWrong'));
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskCompleted);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskCompleted);
		};
	}, [socket]);

	useEffect(() => {
		const hasActiveQueue = async () => {
			const response = await getPatientQueueCount(getUserId());
			if (!response.error) {
				setUserWaitingRoomCount(response.queueSize);
			}
		};

		const getRequests = async () => {
			const params = {
				doctorGuidId: getUserId(),
				type: AssignedRequestTypes.WITHOUT_INTAKE_FORM,
			};
			const response = getConfigurationValue(companyConfigurations[TelemedicineModesSettings.SHARED_QUEUE])
				? await getLevelAssignedRequests({ healthSystemId: user.userSession.healthSystem.id, ...params })
				: await getProviderAssignedRequests(params);
			if (!response.error) {
				setUserWaitingRoomCount(response.totalCount);
			}
		};
		if (userRole === UserRoles.DOCTOR && !isPrimaryCareType) {
			getRequests();
		}
		if (isPrimaryCareType && [UserRoles.DIGITAL_CLINICIAN, UserRoles.DOCTOR, UserRoles.NURSE].includes(userRole)) {
			hasActiveQueue();
		}
	}, [userRole, isPrimaryCareType]);

	useEffect(() => {
		const getPatients = async () => {
			if (UserRoles.DOCTOR !== userRole) {
				return;
			}
			const patients = await getMyPatients({
				pageIndex: 0,
				pageSize: 20,
			});
			if (!selectedPatient) {
				dispatch(patientActionCreators.setSelectedPatient(patients.length > 0 ? patients[0] : null));
			}
		};
		getPatients();
	}, [dispatch, userRole]);

	const onLeftNavigationItemClicked = (item, event) => {
		const checkInLocation = '/check-in-patient';
		const patientId = getStorage().getItem('patientId');
		if (item.role === 'toggleLeftNavigation' || history.location.pathname === checkInLocation) {
			if (patientId && history.location.pathname === checkInLocation) {
				event.preventDefault();
				openCheckInNewPatient(item.linkTo);
			} else {
				history.push(item.linkTo);
			}
			if (item.role === 'toggleLeftNavigation') {
				toggleLeftNavigation();
			}
		}
	};

	const openCheckInNewPatient = link => {
		const checkInLocation = '/check-in-patient';
		const patientId = getStorage().getItem('patientId');
		if (patientId && history.location.pathname === checkInLocation) {
			setIsNewPatientCheckInModalVisibility(true);
			setSelectedPage(link);
			return;
		}
		history.push(link || checkInLocation);
	};

	const checkInNewPatient = () => {
		getStorage().removeItem('patientId');
		if (!selectedPage) {
			history.push('/check-in-patient');
		} else {
			history.push(selectedPage);
		}
		setIsNewPatientCheckInModalVisibility(false);
	};

	const closeCheckInModal = () => {
		setSelectedPage('');
		setIsNewPatientCheckInModalVisibility(false);
	};

	const getDarkModeLoaderColor = () =>
		user.darkMode &&
		[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) &&
		DarkTheme.colors.grayThree;

	const getAlertMessageContent = data => ({
		title: data.measurementAlertType.name,
		contentText: translate('patientHasMeasured', {
			value1: data.patientFullName,
			value2: data.measurementAlertType.name,
		}),
	});

	const handleMeasurementAlertClick = () => {
		if (healthSystems.measurementAlertData.patientUserId) {
			const url = `/patients/${healthSystems.measurementAlertData.patientUserId}`;
			dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(null));
			history.push(url);
		}
	};

	return (
		<>
			{isLoading && (
				<Grid
					columns='1fr'
					rows='1fr'
					stretch='100vh'
					horizAlign='center'
					vertAlign='center'
					backgroundColor={getDarkModeLoaderColor()}>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<Grid
					columns={healthSystems.isFullscreen || shouldApplyResponsive ? '1fr' : '80px 1fr'}
					stretch='100dvh'
					className={classNames(
						'main-layout new-experience',
						locale === LOCALES.ARABIC ? 'direction-rtl-wrapper' : '',
						[UserRoles.VISITOR, UserRoles.FAMILY_MEMBER].includes(getUserRole()) ? 'visitor-view' : '',
						healthSystems.isFullscreen ? 'full-screen-wrapper' : '',
						user.darkMode ? 'dark-mode' : ''
					)}>
					{!healthSystems.isFullscreen && (
						<LeftNavigationMayo
							links={[]}
							onItemClicked={onLeftNavigationItemClicked}
							companyDetails={company.companyDetails}
							companySettings={company.companySettings}
							openCheckInNewPatient={openCheckInNewPatient}
							hasName={false}
							isPrimaryCareType={isPrimaryCareType}
							setIsLoading={setIsLoading}
						/>

					)}
					<div className='app-wrapper'>
						<Grid
							className={props.isSecondColumnCollapsed ? 'collapsed-second-column' : ''}
							columns={props.twoColumns ? '1fr 3fr' : '1fr'}
							stretch='100%'>
							<div className='full-width'>{props.children}</div>
						</Grid>
						{props.showWarningAlert && healthSystems.measurementAlertData && (
							<PopUpAlert
								display={healthSystems.measurementAlertData}
								isRightBottom={true}
								title={getAlertMessageContent(healthSystems.measurementAlertData).title}
								alertType={AlertTypes.DANGER}
								contentText={getAlertMessageContent(healthSystems.measurementAlertData).contentText}
								isSilent={true}
								onTextClick={handleMeasurementAlertClick}
								onAlertClose={() => dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(null))}
								isMeasurementAlert={true}
								selfCloseTimeOut={5000}
							/>
						)}
					</div>
				</Grid >
			)}
			{
				isNewPatientCheckInModalVisible && (
					<Modal
						display={isNewPatientCheckInModalVisible}
						onModalClose={closeCheckInModal}
						onModalSubmit={checkInNewPatient}
						primaryButtonLabel={translate('discardAndContinue')}
						className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
						shouldSubmitOnEnter={false}
						position='center'>
						<Form height={220} className='discard-changes-form'>
							<h3>{translate('discardChanges')}</h3>
							<p>{translate('checkInOtherPatient')}</p>
						</Form>
					</Modal>
				)
			}
			<div className='build-number'>
				<Alert
					display={taskCompleted}
					fixed={true}
					variant='dark'
					message={translate('taskCompleted')}
					onClose={() => setTaskCompleted(null)}
				/>
			</div>
		</>
	);
};

export default MainLayout;
