import translate from 'i18n-translations/translate.jsx';
import {
	clearStorage,
	getCompanyId,
	getUserInfo,
} from 'infrastructure/auth.js';
import {
	decodeHtml,
	getStorage,
	isMobileOrTabletDevice,
} from 'infrastructure/helpers/commonHelpers.js';
import { buildProfilePicUrl } from 'infrastructure/helpers/thumbnailHelper.js';
import React, { useEffect, useRef, useState } from 'react';
import Dropdown from 'components/Dropdown.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import {
	AlertTypes,
	HealthcareErrorCode,
	PresenceStatusType,
} from 'constants/enums.js';
import { useSelector } from 'react-redux';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import ConfigurableMenu from 'components/ConfigurableMenu.jsx';
import { updateSession } from 'api/users.js';
import Alert from 'components/Alert.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import _ from 'lodash';
import classNames from 'classnames';

const UserAccount = props => {
	const userInfo = getUserInfo();
	const healthSystems = useSelector(state => state.healthSystems);
	const wrapperRef = useRef(null);

	const [error, setError] = useState(null);
	const [showDropdownOnTouch, setShowDropdownOnTouch] = useState('');
	const [isMenuSettingsOpen, setIsMenuSettingsOpen] = useState(false);
	const [updateTreeError, setUpdateTreeError] = useState('');

	const companyId = getCompanyId();
	const history = useHistory();
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const setUserSession = session => dispatch(userActionCreators.setUserSession(session));


	const handleTouchOutsideItem = event => {
		if (!wrapperRef.current || wrapperRef.current.contains(event.target)) {
			return;
		}
		setShowDropdownOnTouch('');
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleTouchOutsideItem);
		return () => {
			document.removeEventListener('mousedown', handleTouchOutsideItem);
		};
	}, []);


	const checkOut = async checkedInChannelId => {
		const response = await updateSession({
			healthSystemId: user.userSession.healthSystem.id,
			checkedInChannelId,
			floorId: null,
			departmentId: null,
			companyId,
		});
		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
				setError(translate('patientInQueue'));
			} else if (response.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
				setError(translate('medicalVisitInitiated'));
			} else {
				setError(response.error.message);
			}
			return;
		}
		getStorage().removeItem('helloDeviceId');
		setUserSession({ ...user.userSession, checkedInChannelId: null });
	};

	const signOut = () => {
		if (user.userSession.checkedInChannelId) {
			checkOut(null);
		}
		clearStorage();
		history.push('/logout');
	};

	return (
		<li>
			<Dropdown
				className='user header-user-dropdown'
				position='right'
				stayOpenOnClick={isMobileOrTabletDevice()}
				hasImageWrapper={true}
				isPortal={true}
				setIsPortalOpen={() => {}}
				portalClassName={classNames('portal-user-dropdown__items', { 'dark-mode': user.darkMode })}
				title={
					<div>
						<p>{decodeHtml(`${userInfo.firstName} ${userInfo.lastName}`)}</p>
						{healthSystems && (
							<p>
								<span>
									<i
										className={`material-icons ${
											user.presenceStatusTypeId === PresenceStatusType.AVAILABLE ? '--green-color' : '--red-color'
										}
                    `}>
										fiber_manual_record
									</i>
								</span>
							</p>
						)}
					</div>
				}
				imageUrl={buildProfilePicUrl(userInfo.profilePicture || `${healthCareCdnUrl}header/no-pic.svg`, 50)}>
				<div className='dropdown__items portal-header-user-dropdown'>
					<ul className='list-group' data-cy='listOfItems'>
						<li>
							<span onClick={signOut}>
								<img src={`${healthCareCdnUrl}header/sign-out-new.svg`} alt='icon' />
								{translate('logOut')}
							</span>
						</li>
					</ul>
				</div>
			</Dropdown>
			{isMenuSettingsOpen && <ConfigurableMenu setIsMenuSettingsOpen={() => setIsMenuSettingsOpen(prevState => !prevState)} />}
			<Alert display={updateTreeError} fixed={true} hideCloseButton={true} message={updateTreeError} variant='dark' />
			<PopUpAlert
				alertType={AlertTypes.WARNING}
				display={error}
				onAlertClose={() => setError('')}
				contentText={error}
				isSilent={true}
				center={true}
			/>
		</li>
	);
};

export default UserAccount;
