import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getUserRole, isAuthenticated } from 'infrastructure/auth.js';
import { HealthSystemType, UserRoles } from 'constants/enums.js';
import Unauthorized from 'components/Unauthorized.jsx';
import {
	getProperUrl,
	isUserUnauthorized,
} from 'infrastructure/helpers/commonHelpers.js';
import ContinueAs from 'containers/ContinueAs.jsx';
import AlertCenterMayo from 'views/AlertCenterMayo.jsx';

const Home = () => {
	const userSession = useSelector(state => state.user.userSession);
	const configurations = useSelector(state => state.configurations);

	const getComponentToRender = () => {
		const result = { component: null, url: '/' };
		const userRole = getUserRole();
		if (!isAuthenticated()) {
			return <Redirect to='/login' />;
		}

		result.url = getProperUrl(configurations, userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE);
		if (!userRole || isUserUnauthorized()) {
			result.url = '/continue-as';
			result.component = <ContinueAs />;
		}
		switch (userRole) {
			case UserRoles.NURSE: 
			case UserRoles.VIRTUAL_SITTER: {
				result.component = <AlertCenterMayo />;
				break;
			}
			default: {
				result.component = <Unauthorized />;
			}
		}
		window.history.replaceState(null, 'healthcare', result.url);
		return <Redirect to={result.url} />;
	};

	return getComponentToRender();
};

export default Home;
